import * as Data from 'effect/Data';

export class UnexpectedError extends Data.TaggedError('UnexpectedError') {}

export class UnexpectedServerError extends Data.TaggedError(
  'UnexpectedServerError',
) {}
export class ServerUnavailableError extends Data.TaggedError(
  'ServerUnavailableError',
) {}

export class EntityNotFoundError extends Data.TaggedError(
  'EntityNotFoundError',
) {}

export class ListPageNotFoundError extends Data.TaggedError(
  'ListPageNotFoundError',
) {}

export type InfrastructureError =
  | ServerUnavailableError
  | UnexpectedServerError;

/** Common error cases for fetching a single entity. */
export type GetEntityError<TNotFound> =
  | TNotFound
  | InfrastructureError
  | UnexpectedError;

/** Common error cases for fetching a list if entities. */
export type GetListError =
  | ListPageNotFoundError
  | InfrastructureError
  | UnexpectedError;

/** Common error cases for deleting an entity. */
export type DeleteEntityError =
  | EntityNotFoundError
  | InfrastructureError
  | UnexpectedError;

import * as Data from 'effect/Data';
import * as Result from './result';

/**
 * The `Deferred Data` model represents data that is being fetched from
 * a remote source.
 */
export type DeferredData<Success, Failure> = Data.TaggedEnum<{
  /**  Loading state. */
  Loading: NonNullable<void>;
  /** Sucess state. */
  Success: { readonly data: Success };
  /** Failure state. */
  Failure: { readonly reason: Failure };
}>;

export interface DeferredDataDefinition
  extends Data.TaggedEnum.WithGenerics<2> {
  readonly taggedEnum: DeferredData<this['A'], this['B']>;
}

const _internal = Data.taggedEnum<DeferredDataDefinition>();

/** Fetching data failed. */
export const Failure = _internal.Failure;
/** Fetching in progress. */
export const Loading = _internal.Loading;
/** Data successfully fetched. */
export const Success = _internal.Success;
export const match = _internal.$match;

/**
 * Creates a `DeferredData` instance from a `Result` instance.
 *
 * @category constructors
 */
export const fromResult = <A, E>(
  result: Result.Result<A, E>,
): DeferredData<A, E> =>
  Result.match(result, {
    onFailure: (reason) => Failure({ reason }),
    onSuccess: (data) => Success({ data }),
  });

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { logger } from '@fmnts/common/log';
import { Observable, catchError, tap } from 'rxjs';

/**
 * Service that provides a "safety net" mechanism in order to prevent
 * data being lost.
 */
@Injectable({ providedIn: 'root' })
export class SafetyNetService {
  /** URL of the 'raw' API */
  private readonly rawApiUrl = '/api/internal/raw/';
  private readonly http = inject(HttpClient);
  private readonly _log = logger({ name: SafetyNetService.name });

  /**
   * Persists the given `records` on the server.
   * This can be abritrary data and doesn't not need to
   * represent any model on our server side infrastructure.
   *
   * @param records Data to be stored
   *
   * @returns
   * An `Observable` that will emit with the response
   *
   * @example
   * service.storeRecords([{anyData: true}]).pipe(
   *  // Since this is a http request make sure to
   *  // handle any error.
   *  catchError(() => of('OK'))
   * );
   */
  createRecords(records: unknown[]): Observable<unknown> {
    return this.http
      .post(this.rawApiUrl, records, { responseType: 'text' })
      .pipe(
        tap(() => {
          this._log.info(
            `Uploaded ${records.length} record(s) to raw API`,
            records,
          );
        }),
        catchError((e) => {
          this._log.warn(
            `Upload of ${records.length} record(s) to raw API failed`,
            records,
            e,
          );
          throw e;
        }),
      );
  }
}

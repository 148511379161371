import * as Data from 'effect/Data';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';

/**
 * The `Resolvable` model represents data that may be resolved or unresolved.
 */
export type Resolvable<A, ForeignKey> = Data.TaggedEnum<{
  /** Unresolved foreign key. */
  Unresolved: { readonly ref: ForeignKey };
  /** Resolved reference. */
  Resolved: { readonly value: A };
}>;

export interface ResolvableDefinition extends Data.TaggedEnum.WithGenerics<2> {
  readonly taggedEnum: Resolvable<this['A'], this['B']>;
}

const _internal = Data.taggedEnum<ResolvableDefinition>();

export const isUnresolved = _internal.$is('Unresolved');
export const isResolved = _internal.$is('Resolved');
/** Unresolved data. */
export const Unresolved = _internal.Unresolved;
/** Resolved data. */
export const Resolved = _internal.Resolved;
export const match = _internal.$match;

export const fromOption: {
  /**
   * @example
   * ```ts
   * import { Resolvable } from "@causality/core"
   *
   * assert.deepStrictEqual(Resolvable.fromOption(Option.some('value'), () => 1), Resolvable.Resolved('value'))
   * assert.deepStrictEqual(Resolvable.fromOption(Option.none(), () => 1), Resolvable.Unresolved(1))
   * ```
   *
   * @category constructors
   */
  <ForeignKey>(
    onNone: () => ForeignKey,
  ): <A>(self: O.Option<A>) => Resolvable<A, ForeignKey>;
  /**
   * @example
   * ```ts
   * import { Resolvable } from "@causality/core"
   *
   * assert.deepStrictEqual(Resolvable.fromOption(Option.some('value'), () => 1), Resolvable.Resolved('value'))
   * assert.deepStrictEqual(Resolvable.fromOption(Option.none(), () => 1), Resolvable.Unresolved(1))
   * ```
   *
   * @category constructors
   */
  <A, ForeignKey>(
    self: O.Option<A>,
    onNone: () => ForeignKey,
  ): Resolvable<A, ForeignKey>;
} = Fn.dual(2, <A, Ref>(self: O.Option<A>, onNone: () => Ref) =>
  Fn.pipe(
    self,
    O.map((value) => Resolved({ value })),
    O.getOrElse(() => Unresolved({ ref: onNone() })),
  ),
);

<div #formControl class="form-control" (click)="toggleInput()">
  <fa-icon [icon]="iconCalendar" />

  <span class="value">{{ value | dateRange: localeId() }}</span>

  <span class="toggle">
    <fa-icon [icon]="iconOpen" [rotate]="showInput ? 180 : undefined" />
  </span>
</div>

<div #dropdown class="date-range-picker-selector" [class.!hidden]="!showInput">
  @if (allowDeselect && fromDate && toDate) {
    <div class="deselect" (click)="input = null; toggleInput(false)">
      {{ 'clear_filter' | translate }}
    </div>
  }

  <div class="date-range-picker-row">
    <div class="ranges">
      @for (range of ranges; track range) {
        <div class="range" (click)="range.select(); toggleInput(false)">
          {{ range.label | translate }}
        </div>
      }
    </div>

    <shared-calendar-picker
      [selectedFromDate]="fromDate"
      [selectedToDate]="toDate"
      [range]="true"
      (selectedRangeChanged)="input = $event; toggleInput(false)"
    />
  </div>
</div>

import { computed, inject, Signal } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Url } from '@fmnts/core/url';
import * as O from 'effect/Option';
import { RouterUrlService } from './router-url.service';

/**
 * @returns
 * A signal that emits with an URL tree for a back navigation.
 */
export function navigateBackUrlTree(
  router = inject(Router),
  routerUrlService = inject(RouterUrlService),
): Signal<O.Option<UrlTree>> {
  const previous = routerUrlService.previous;
  return computed(() =>
    previous().pipe(
      O.map(Url.getRelativePath),
      O.map((url) => router.parseUrl(url)),
    ),
  );
}

import { NgModule } from '@angular/core';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import {
  EmptyStateDescriptionComponent,
  EmptyStateTitleComponent,
} from './empty-state-text.component';
import { EmptyStateVisualComponent } from './empty-state-visual.component';
import { EmptyStateComponent } from './empty-state.component';

const exported = [
  EmptyStateComponent,
  EmptyStateDescriptionComponent,
  EmptyStateTitleComponent,
  EmptyStateVisualComponent,
];

@NgModule({
  exports: exported,
  imports: [FmntsLayoutModule, ...exported],
})
export class FmntsEmptyStateModule {}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FmntsCommonModule } from '@fmnts/common';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { isDefined, isNil, Nullish } from '@fmnts/core';
import { TimeSpan } from '@fmnts/core/chronos';
import { I18nModule, LocaleService } from '@fmnts/i18n';
import { faCalendar, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { debounceTime, fromEvent, Subscription } from 'rxjs';
import { CalendarPickerComponent } from '../calendar-picker/calendar-picker.component';

@Component({
  selector: 'shared-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
  imports: [
    FmntsIconsModule,
    MomentModule,
    I18nModule,
    CalendarPickerComponent,
    FmntsCommonModule,
  ],
})
export class DateRangePickerComponent
  implements ControlValueAccessor, OnDestroy
{
  protected readonly localeId = inject(LocaleService).localeId;

  @HostBinding('class') componentClass = 'date-range-picker';

  @Input() allowDeselect = false;

  // currently only used in the launcher to display the Date-Range-Picker
  // just below the input field
  @Input() initialPosition: boolean | undefined;

  @ViewChild('dropdown', { static: true })
  dropdownElement!: ElementRef<HTMLDivElement>;

  @ViewChild('formControl', { static: true })
  formControl!: ElementRef<HTMLDivElement>;

  private _resizeEvent$: Subscription;

  public fromDate: moment.Moment | null = null;
  public toDate: moment.Moment | null = null;
  public showInput = false;
  protected readonly ranges: ReadonlyArray<{
    label: string;
    select: () => void;
  }> = [
    { label: 'today', select: () => this.selectToday() },
    { label: 'yesterday', select: () => this.selectYesterday() },
    { label: 'this_week', select: () => this.selectThisWeek() },
    { label: 'last_week', select: () => this.selectLastWeek() },
    { label: 'this_month', select: () => this.selectThisMonth() },
    { label: 'last_month', select: () => this.selectLastMonth() },
    { label: 'this_year', select: () => this.selectThisYear() },
    { label: 'last_year', select: () => this.selectLastYear() },
  ];

  get value(): TimeSpan | null {
    if (this.fromDate && this.toDate) {
      return [this.fromDate, this.toDate];
    }
    return null;
  }

  protected readonly iconCalendar = faCalendar;
  protected readonly iconOpen = faCaretDown;

  private _onChange: ChangeCallback<TimeSpan | null> = () => {};
  private _onTouched: TouchedCallback = () => {};

  constructor(private _renderer: Renderer2) {
    this._resizeEvent$ = fromEvent(window, 'resize', { passive: true })
      .pipe(debounceTime(100))
      .subscribe((value) => this.alignDateRangePicker());
  }
  ngOnDestroy(): void {
    this._resizeEvent$.unsubscribe();
  }

  @HostListener('document:keydown.escape')
  onKeydownHandler(): void {
    this.toggleInput(false);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:select-opened', ['$event'])
  clickOutsideHandler($event: MouseEvent | CustomEvent): void {
    if ($event.type === 'select-opened') {
      if ($event.detail !== this) {
        this.toggleInput(false);
      }
    } else if (this.showInput) {
      $event.stopPropagation();
      this.toggleInput(false);
    }
  }

  @HostListener('click', ['$event'])
  clickInsideHandler($event: MouseEvent): void {
    $event.stopPropagation();

    if (this.showInput) {
      document.dispatchEvent(
        new CustomEvent('select-opened', { detail: this }),
      );
    }
  }

  set input(date: TimeSpan | Nullish) {
    if (!this.allowDeselect && isNil(date)) {
      return;
    }

    this._updateValue(date);
    this._emitChange();
  }

  writeValue(value: TimeSpan | Nullish): void {
    this._updateValue(value);
  }

  registerOnChange(fn: ChangeCallback): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }

  private _emitChange() {
    this._onChange(this.value);
  }

  private _updateValue(date: TimeSpan | Nullish) {
    const [fromDate, toDate] = date ?? [];

    if (fromDate && toDate) {
      this.fromDate = moment(fromDate);
      this.toDate = moment(toDate);
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  toggleInput(forceValue?: boolean): void {
    if (isDefined(forceValue)) {
      this.showInput = forceValue;
    } else {
      this.showInput = !this.showInput;
    }
    if (this.showInput) {
      this.alignDateRangePicker();
    }
  }

  private selectToday() {
    this.input = [moment(), moment()];
  }

  private selectYesterday() {
    this.input = [moment().subtract(1, 'day'), moment().subtract(1, 'day')];
  }

  private selectThisWeek() {
    this.input = [moment().startOf('week'), moment().endOf('week')];
  }

  private selectLastWeek() {
    this.input = [
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week'),
    ];
  }

  private selectThisMonth() {
    this.input = [moment().startOf('month'), moment().endOf('month')];
  }

  private selectLastMonth() {
    this.input = [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ];
  }

  private selectThisYear() {
    this.input = [moment().startOf('year'), moment().endOf('year')];
  }

  private selectLastYear() {
    this.input = [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ];
  }

  /**
   * Aligns the Date-Range-Picker upon opening
   *
   * When initialPosition is set, the Date-Range-Picker will be shown in
   * the middle under the input field.
   * Depending on the position of the date-input Control on the window
   * the Date-Range-Picker will be anchored on the left or on the right side.
   */
  private alignDateRangePicker() {
    if (this.initialPosition) {
      return;
    }

    const rect = this.formControl.nativeElement.getBoundingClientRect();
    const side = rect.left < window.innerWidth / 2 ? 'left' : 'right';
    this.setElementAnchor(this.dropdownElement.nativeElement, side);
  }

  private setElementAnchor(el: HTMLElement, side: string) {
    this._renderer.setStyle(el, 'left', 'initial');
    this._renderer.setStyle(el, 'right', 'initial');
    this._renderer.setStyle(el, side, '0px');
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Title for an empty state.
 */
@Component({
  selector: 'fmnts-empty-state-title, [fmnts-empty-state-title]',
  standalone: true,
  template: '<ng-content />',
  styleUrl: './empty-state-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateTitleComponent {
  @HostBinding('class.fmnts-empty-state-title') componentClass =
    'fmnts-empty-state-title';
}

/**
 * Description for an empty state.
 */
@Component({
  selector: 'fmnts-empty-state-description, [fmnts-empty-state-description]',
  standalone: true,
  template: '<ng-content />',
  styleUrl: './empty-state-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateDescriptionComponent {
  @HostBinding('class.fmnts-empty-state-description') componentClass =
    'fmnts-empty-state-description';
}

import { inject, Injectable, signal } from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { WINDOW } from '@fmnts/common/browser';
import { Url } from '@fmnts/core/url';
import * as O from 'effect/Option';
import { filter, map, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterUrlService {
  private readonly router = inject(Router);
  private readonly withBase = Url.mergeWithBase(
    Url.AbsoluteUrl(inject(WINDOW).location.origin),
  );

  private readonly _previous = signal<O.Option<URL>>(O.none());
  private readonly _current = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(({ url }) => this.withBase(url)),
    ),
    { initialValue: this.withBase(this.router.url), equal: Url.EquivalenceURL },
  );

  /** The previous url. */
  readonly previous = this._previous.asReadonly();
  /** The current url. */
  readonly current = this._current;

  constructor() {
    toObservable(this._current)
      .pipe(
        // Skip the first event if the current url is the same as the base url.
        filter(
          (url, i) => i !== 0 || !Url.EquivalenceURL(url, this.withBase('')),
        ),
        pairwise(),
        takeUntilDestroyed(),
      )
      .subscribe(([previousUrl]) => {
        this._previous.set(O.some(previousUrl));
      });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * An empty state appears when there is no data to display and describes what the user can do next.
 */
@Component({
  selector: 'fmnts-empty-state',
  standalone: true,
  templateUrl: 'empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
  @HostBinding('class.fmnts-empty-state')
  protected readonly componentClass = 'fmnts-empty-state';
}

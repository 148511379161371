import {
  RouterReducerState,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';

/** Feature key that the router uses to register itself. */
export const ROUTER_FEATURE_KEY = 'router';

/** Router state. */
export type RouterFeatureState =
  RouterReducerState<SerializedRouterStateSnapshot>;

export interface RouterPartialState {
  readonly [ROUTER_FEATURE_KEY]: RouterFeatureState;
}

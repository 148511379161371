import { ActivatedRouteSnapshot, Data, Params } from '@angular/router';
import { paginationQueryParamsSerializer } from '@formunauts/shared/view-options/data-access';
import { getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ROUTER_FEATURE_KEY, RouterFeatureState } from './router.reducer';

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParam,
  selectQueryParams,
  selectRouteData,
  selectRouteParam,
  selectRouteParams,
  selectTitle,
  selectUrl,
} = getRouterSelectors();

/**
 * Selector to access the whole feature store state of the router.
 */
const selectRouterFeatureState =
  createFeatureSelector<RouterFeatureState>(ROUTER_FEATURE_KEY);

/**
 * Selector to access the root activated route.
 */
const selectRoot = createSelector(
  selectRouterFeatureState,
  (s) => s.state.root,
);

/**
 * Helper function to aggregate the route parameter along a snapshot and its children.
 *
 * The given `predicate` will be called for each snapshot and if it returns true the
 * params of this snapshot are added to the result.
 *
 * @param snapshot Snapshot
 * @param predicate Predicate that controls if the params are added to the result.
 *
 * @returns
 * All aggregated params of activated route snapshots fulfilling the predicate
 * along the route starting at the given snapshot.
 */
function aggregateRouteParams(
  snapshot: ActivatedRouteSnapshot,
  predicate: (s: ActivatedRouteSnapshot) => boolean,
) {
  // Add params of current snapshot if predicate is fulfilled.
  let params: Params = predicate(snapshot) ? snapshot.params : {};

  // Aggregate all child params as well
  for (const c of snapshot.children) {
    params = {
      ...params,
      ...aggregateRouteParams(c, predicate),
    };
  }

  return params;
}

/**
 * Helper function to aggregate the route data along a snapshot and its children.
 *
 * The given `predicate` will be called for each snapshot and if it returns true the
 * data of this snapshot are added to the result.
 *
 * @param snapshot Snapshot
 * @param predicate Predicate that controls if the data are added to the result.
 *
 * @returns
 * All aggregated data of activated route snapshots fulfilling the predicate
 * along the route starting at the given snapshot.
 */
function aggregateRouteData(
  snapshot: ActivatedRouteSnapshot,
  predicate: (s: ActivatedRouteSnapshot) => boolean,
) {
  // Add params of current snapshot if predicate is fulfilled.
  let data: Data = predicate(snapshot) ? snapshot.data : {};

  // Aggregate all child params as well
  for (const c of snapshot.children) {
    data = {
      ...data,
      ...aggregateRouteData(c, predicate),
    };
  }

  return data;
}

interface PredicateOptions {
  /**
   * Name of the route outlet
   *
   * `'primary'` if no named outlet is used
   */
  outlet: string;
}

/**
 * Helper that creates a predicate function from common predicate options
 * for easier use with options only.
 */
function predicateFunctionFromOptions(
  opts: PredicateOptions,
): (s: ActivatedRouteSnapshot) => boolean {
  return (s) => s.outlet === opts.outlet;
}

/**
 * Selects all route parameters for activated routes matching the
 * given options.
 *
 * @param options Options to filter result
 */
export const selectAllRouteParams = (options: PredicateOptions) =>
  createSelector(selectRoot, (root) =>
    aggregateRouteParams(root, predicateFunctionFromOptions(options)),
  );

/**
 * Selects a route parameter with name `param` from all activated routes matching
 * the given options.
 *
 * @param param Name of the route param that should be selected
 * @param options Options to filter result
 */
export const selectAllRouteParam = (param: string, options: PredicateOptions) =>
  createSelector(selectAllRouteParams(options), (params) => params[param]);

/**
 * Selects pagination information from Url query params.
 */
export const selectPaginationFromUrl = createSelector(
  selectQueryParams,
  (params) => paginationQueryParamsSerializer.deserialize(params),
);

/**
 * Selects all route data for activated routes matching the
 * given options.
 *
 * @param options Options to filter result
 */
export const selectAllRouteDatas = (options: PredicateOptions) =>
  createSelector(selectRoot, (root) =>
    aggregateRouteData(root, predicateFunctionFromOptions(options)),
  );

/**
 * Selects a route data with name `dataKey` from all activated routes matching
 * the given options.
 *
 * @param dataKey Name of the route data that should be selected
 * @param options Options to filter result
 */
export const selectAllRouteData = (
  dataKey: string,
  options: PredicateOptions,
) => createSelector(selectAllRouteDatas(options), (data) => data[dataKey]);

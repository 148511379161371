<div class="form-control" (click)="toggleInput()">
  <fa-icon [icon]="iconCalendar" />

  <span class="value">
    @if (date) {
      <!-- use non-null assertion, because locale$ emits value on subscription -->
      {{ date | amLocale: localeId() | amDateFormat: 'LL' }}
    } @else {
      -
    }
  </span>

  <span class="toggle">
    <fa-icon [icon]="iconOpen" [rotate]="showInput ? 180 : undefined" />
  </span>
</div>

<div #dropdown class="date-picker-selector" [class.!hidden]="!showInput">
  @if (date) {
    <div class="deselect" (click)="writeValue(null); toggleInput(false)">
      {{ 'clear_filter' | translate }}
    </div>
  }
  <shared-calendar-picker
    [selectedDate]="date"
    [firstPossibleDate]="firstPossibleDate"
    [lastPossibleDate]="lastPossibleDate"
    (selectedDateChanged)="writeValue($event); toggleInput(false)"
  />
</div>
